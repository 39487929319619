import { matchAndThriveApi } from 'api'

interface Props {
  firstname?: string
  lastname?: string
  email: string
  phone?: string
  image?: string
  role?: string
  company?: string
  country?: string
  KOID?: string
  manager?: {
    name: string
    surname: string
    email: string
  }
}

export const updateUser = ({
  firstname,
  lastname,
  email,
  phone,
  image,
  role,
  company,
  country,
  KOID,
  manager,
}: Props) =>
  matchAndThriveApi.patch<{ data: any }>('/users/ensure', {
    firstname,
    lastname,
    email,
    phone,
    image,
    role,
    company,
    country,
    KOID,
    manager,
  })
